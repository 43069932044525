<template>
    <div style="margin-top: 80px;">
        <BasePageBreadCrumb :pages="pages" :title="id ? 'Editar cliente' : 'Novo cliente'" :menu="menu"/>
        <div class="container-fluid qsuite-c-fluid-0">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-bg-trans">
                            <div class="card-body pt-4 px-0">
                                <b-tabs>
                                    <form class="px-2 needs-validation" novalidate @submit.prevent="save">
    
                                        <b-tab class="tab-pane" id="dadospessoais">
                                            <template #title>
                                                <div class="d-flex">
                                                    <i class="far fa-list-alt font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Informações <b-badge variant="danger" v-show="error_information">1</b-badge></span>
                                                </div>
                                            </template>
    
                                                <div class="form-body">
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group mb-2">
                                                                <label>Tipo de cadastro </label>
                                                            </div>
                                                            
                                                                <b-form-radio-group
                                                                v-model="client.type_registration"
                                                                name=""> 
                                                            <div class="form-check form-check-inline">
                                                                <b-form-radio 
                                                                value="natural_person" @change="getype($event)">Pessoa física</b-form-radio>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <b-form-radio  
                                                                value="legal_entity" @change="getype($event)">Pessoa jurídica</b-form-radio>
                                                            </div>
                                                            </b-form-radio-group>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-4">
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group mb-2">
                                                                <label>Permissão de cadastro </label>
                                                            </div>
                                                                <b-form-checkbox-group
                                                                v-model="client.type"
                                                                name="">
                                                            <div class="form-check form-check-inline">
                                                                <b-form-checkbox 
                                                                value="CLIENT">Cliente/Proprietário</b-form-checkbox>
                                                            </div>
                                                            <div class="form-check form-check-inline">
                                                                <b-form-checkbox  
                                                                value="LESSE">Locatário</b-form-checkbox>
                                                            </div>
                                                            </b-form-checkbox-group>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mt-4">
                                                    <div class="col-12 col-md-6 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Código do cliente</label>
                                                            <input type="text" class="form-control" placeholder="Gerado automaticamente" v-model="id" disabled>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Cliente desde </label>
                                                            <input type="date" class="form-control" placeholder="21/01/2021" name="client_since_date" v-model="client.client_since_date">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Data cadastro </label>
                                                            <input type="date" class="form-control" placeholder="21/01/2021" disabled>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Como conheceu a empresa? </label>
                                                            <select class="custom-select mr-sm-2" v-model="client.source">
                                                                <option :value="null">Selecione</option>
                                                                <option value="GOOGLE">Google</option>
                                                                <option value="SOCIAL_MEDIA">Rede Social</option>
                                                                <option value="RADIO">Rádio</option>
                                                                <option value="OUTDOOR">Outdoor</option>
                                                                <option value="MAGAZINE">Revista</option>
                                                                <option value="INDICATION">Indicação</option>
                                                                <option value="OTHER">Outros</option>
                                                            </select>
                                                        </div>
                                                        <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Definir a origem do lead</small>
                                                    </div>
                                                    <div class="col-12 pt-4"></div>
                                                </div>
                                        </b-tab>
    
                                    <b-tab class="tab-pane" id="dadospessoais" v-if="client.type_registration === 'natural_person'">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-list-alt font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Pessoa física <b-badge variant="danger" v-show="basic_error">1</b-badge></span>
                                            </div>
                                        </template>
    
                                                <div class="form-body mt-4">
                                                    <div class="row">
                                                   
                                                        
                                                        <div class="col-12 pt-4"></div>
                                                        <div class="col-12 col-md-6 col-lg-5">
                                                            <div class="form-group">
                                                                <label>Nome </label>
                                                                <input type="text" class="form-control" v-model="client.first_name" v-validate="client.type_registration === 'natural_person' ? 'required' : ''" name="first_name" :class="{'is-invalid': errors.has('first_name')}">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <div class="form-group">
                                                                <label>Sobrenome </label>
                                                                <input type="text" class="form-control" v-model="client.last_name" v-validate="client.type_registration === 'natural_person' ? 'required' : ''" name="last_name" :class="{'is-invalid': errors.has('last_name')}">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>CPF </label>
                                                                <input type="tel" @blur="verifyDataUser('cpf')" class="form-control" placeholder="000.000.000-00" v-validate="client.type_registration === 'natural_person' ? 'required' : ''" v-model="client.cpf" name="cpf" :class="{'is-invalid': errors.has('cpf')}" v-mask="'###.###.###-##'">
                                                            </div>
                                                        </div>
                        
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>Data de nascimento </label>
                                                                <input type="date" class="form-control" placeholder="" v-model="client.birth_date">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>Sexo </label>
                                                                <select class="custom-select mr-sm-2" v-model="client.gender">
                                                                    <option :value="null">Selecione</option>
                                                                    <option value="MALE">Masculino</option>
                                                                    <option value="FEMALE">Feminino</option>
                                                                    <option value="NONE">Não quero informar</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>Estado civil </label>
                                                                <select class="custom-select mr-sm-2" v-model="client.marital_status">
                                                                    <option :value="null">Selecione</option>
                                                                    <option value="SINGLE">Solteiro(a)</option>
                                                                    <option value="MARRIED_TOTAL">Casado(a) Comunhão de Bens</option>
                                                                    <option value="MARRIED_PARCIAL">Casado(a) Comunhão Parcial de Bens</option>
                                                                    <option value="MARRIED_NONE">Casado(a) Separação de Bens</option>
                                                                    <option value="DIVORCED">Divorciado(a)</option>
                                                                    <option value="SEPARATE">Separado(a) Judicialmente</option>
                                                                    <option value="WIDOWER">Viúvo(a)</option>
                                                                    <option value="OTHERS">União Estável/Outros</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>Grau de instrução </label>
                                                                <select class="custom-select mr-sm-2" v-model="client.alphabetization">
                                                                    <option :value="null">Selecione</option>
                                                                    <option value="NONE">Não Alfabetizado</option>
                                                                    <option value="ELEMENTARY_INCOMPLETE">Ensino Fundamental Incompleto</option>
                                                                    <option value="ELEMENTARY_COMPLETE">Ensino Fundamental Completo</option>
                                                                    <option value="HIGH_SCHOOL_INCOMPLETE">Médio Incompleto</option>
                                                                    <option value="HIGH_SCHOOL_COMPLETE">Médio Completo</option>
                                                                    <option value="COLLEGE_INCOMPLETE">Superior Incomplet</option>
                                                                    <option value="COLLEGE_COMPLETE">Superior Completo</option>
                                                                    <option value="ESPECIALIZATION">Especialização2</option>
                                                                    <option value="MASTER">Mestrado</option>
                                                                    <option value="DOCTORATE">Doutorado</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>Nacionalidade </label>
                                                                <select class="custom-select mr-sm-2" v-model="client.nacionality">
                                                                    <option :value="null">Selecione</option>
                                                                    <option value="BRAZILIAN">Brasileira</option>
                                                                    <option value="FOREIGN">Estrangeira</option>
                                                                    <option value="CIVILIZED">Brasileira Naturalizado</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>Naturalidade </label>
                                                                <div class="row">
                                                                    <div class="col-12 col-md-6">
                                                                        <select class="custom-select mr-sm-2" name="uf" v-model="client.born_state">
                                                                            <option :value="null">Selecione</option>
                                                                            <option v-for="item in states" :key="item.sigla" :value="item.sigla" >{{ item.nome }}</option>
                                                                        </select>
                                                                    </div>
                                                                    <div class="col-12 col-md-6">
                                                                        <select class="custom-select mr-sm-2" v-model="client.born_city">
                                                                            <option :value="null">Selecione</option>
                                                                            <option v-for="item in getCities(client.born_state)" :key="item" :value="item">{{ item }}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="col-12"></div>
                                                        
                                                        <div class="col-12"></div>
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>Número da identidade </label>
                                                                <input type="tel" class="form-control"  v-model="client.rg">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>Órgão emissor </label>
                                                                <input type="text" class="form-control" v-model="client.rg_emissor">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>UF </label>
                                                                <select class="custom-select mr-sm-2" v-model="client.rg_uf">
                                                                    <option :value="null">Selecione</option>
                                                                    <option v-for="item in states" :key="item.sigla" :value="item.sigla">{{ item.nome }}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>Data de emissão </label>
                                                                <input type="date" class="form-control" placeholder="" v-model="client.rg_date">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>Nome do pai </label>
                                                                <input type="text" class="form-control" placeholder="" v-model="client.father_name">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>Nome do mãe </label>
                                                                <input type="text" class="form-control" v-model="client.mother_name">
                                                            </div>
                                                        </div>
                                                        <div class="col-12"></div>
                                                        <br>
                                                        <div class="col-12 py-2">
                                                            <h4 class="card-title">Contatos</h4>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <div class="form-group">
                                                                <label>E-mail </label>
                                                                <input type="email"  @blur="verifyDataUser('email')" class="form-control" placeholder="abc@example.com" v-model="client.email" v-validate="client.type_registration === 'natural_person' ? 'required' : ''" autocomplete="off" name="email" :class="{'is-invalid': errors.has('email')}">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                       
                                                            <div class="form-group">
                                                        
                                                                <div>
                                                                    <label>Telefone </label>
                                                                    <div class="d-flex">
                                                                        <AreaCode :defaultCountry="client.telephone_area_code_iso" @setAreaCode="setPhoneAreaCode"/><input type="tel" class="form-control" placeholder="(44) 4444-4444" v-model="client.telephone" v-mask="['(##) ####-####']" v-validate="client.type_registration === 'natural_person' ? 'required' : ''" name="telephone" :class="{'is-invalid': errors.has('telephone')}"> 
                                                                    </div>
                                                                </div>
                                                               
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <div class="form-group">
                                                                <label>Whatsapp </label>
                                                                <div class="d-flex">
                                                                    <AreaCode :defaultCountry="client.cellphone_area_code_iso" @setAreaCode="setCellPhoneAreaCode"/>
                                                                    <input type="tel" class="form-control" placeholder="(44) 4 4444-4444" v-model="client.cellphone" v-mask="['(##) ####-####', '(##) # ####-####']" v-validate="client.type_registration === 'natural_person' ? 'required' : ''" name="whatsapp" :class="{'is-invalid': errors.has('whatsapp')}">
                                                                </div>
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                                <div class="row">
                                                    <div class="col-12 py-2">
                                                        <h4 class="card-title">Dados do cônjuge</h4>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Nome </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="client.partner_first_name">
                                                            <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o seu nome.</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Sobrenome </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="client.partner_last_name">
                                                            <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o seu sobrenome.</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-3">
                                                        <div class="form-group">
                                                            <label>CPF </label>
                                                            <input type="tel" class="form-control" placeholder="000.000.000-00" v-model="client.partner_cpf" v-mask="'###.###.###-##'">
                                                        </div>
                                                    </div>
                    
                                                    <div class="col-12 col-md-6 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Data de nascimento </label>
                                                            <input type="date" class="form-control" placeholder="" v-model="client.partner_birth_date">
                                                        </div>
                                                    </div>
                                                </div>
                                    </b-tab>
    
                                    <b-tab class="tab-pane" id="pessoajuridica" v-else>
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-list-alt font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Pessoa jurídica <b-badge variant="danger" v-show="legal_entity_error">1</b-badge></span>
                                            </div>
                                        </template>
    
                                                <div class="form-body mt-4">
                                                    <div class="row">
                                                
                                                        <div class="col-12 pt-4"></div>
                                                      
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <div class="form-group">
                                                                <label>Nome fantasia </label>
                                                                <input type="text" class="form-control" v-model="client.fantasy_name" name="fantasy_name" :class="{'is-invalid': errors.has('email')}" >
                                                             <!-- v-validate="client.type_registration !== 'natural_person' ? 'required' : ''" -->
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <div class="form-group">
                                                                <label>Razão social </label>
                                                                <input type="text" name="social_name" class="form-control" v-validate="client.type_registration !== 'natural_person' ? 'required' : ''" v-model="client.social_name" :class="{'is-invalid': errors.has('email')}">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-4">
                                                            <div class="form-group">
                                                                <label>Cnpj </label>
                                                                <input type="tel" v-validate="client.type_registration === 'natural_person' ? 'required' : ''" class="form-control" placeholder="00.000.000/0000-00" v-model="client.cnpj" name="cnpj" :class="{'is-invalid': errors.has('email')}" v-mask="'##.###.###/####-##'">
                                                            </div>
                                                        </div>
                        
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>Abertura da empresa </label>
                                                                <input type="date" class="form-control" name="company_date_foundation" placeholder="" v-model="client.company_date_foundation">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>Contato </label>
                                                                <div class="d-flex">
                                                                    <AreaCode :defaultCountry="client.company_phone_area_code_iso" @setAreaCode="setCompanyAreaCode"/>
                                                                    <input type="tel"  class="form-control" placeholder="(44) 4444-4444" v-model="client.company_phone"   name="company_phone" v-mask="'(##) #####-####'">
                                                                    <!-- v-validate="client.type_registration !== 'natural_person' ? 'required' : ''" -->
                                                                </div>
                                                               
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label>Email comercial </label>
                                                                <input v-validate="client.type_registration !== 'natural_person' ? 'required' : ''" type="email" class="form-control" v-model="client.email" name="email"  :class="{'is-invalid': errors.has('email')}">
                                                            </div>
                                                        </div>
    
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>Inscrição estadual </label>
                                                                <input type="text" class="form-control" v-model="client.insc_state" name="insc_state" >
                                                            </div>
                                                        </div>
    
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>Inscrição municipal </label>
                                                                <input type="text" class="form-control" v-model="client.insc_city" name="insc_city" >
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-12 col-md-6 col-lg-3">
                                                            <div class="form-group">
                                                                <label>UF </label>
                                                                <select class="custom-select mr-sm-2" v-model="client.company_state">
                                                                    <option :value="null">Selecione</option>
                                                                    <option v-for="item in states" :key="item.sigla" :value="item.sigla">{{ item.nome }}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-3">
                                                            <label>Cidade </label>
                                                            <select class="custom-select mr-sm-2" v-model="client.company_city">
                                                                <option :value="null">Selecione</option>
                                                                <option v-for="item in getCities(client.company_state)" :key="item" :value="item">{{ item }}</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-12"></div>
                                                       
                                                    </div>
                                                </div>
                                                
                                    </b-tab>
    
                                    <b-tab class="tab-pane" id="enderecoresidencial">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-map font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Endereços <b-badge variant="danger" v-show="address_error">1</b-badge></span>
                                            </div>
                                        </template>
                                            <div class="form-body">
                                                <div class="row mt-4" v-if="client.type_registration === 'natural_person'">
                                                    <div class="col-12 pt-4 pb-2">
                                                        <h4 class="card-title">Endereço residencial</h4>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-3">
                                                        <div class="form-group">
                                                            <label>CEP </label>
                                                            <input type="tel" class="form-control" placeholder="74.830-500" v-model="client.cep" @change="getAddress($event)">
                                                        </div>
                                                    </div>
                                                    <div class="col-12"></div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Rua/Avenida/Alameda/Travessa </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="client.street">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-2">
                                                        <div class="form-group">
                                                            <label>Número </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="client.number">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Complemento </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="client.complement">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Bairro </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="client.neighborhood">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-2">
                                                        <div class="form-group">
                                                            <label>UF </label>
                                                            <select class="custom-select mr-sm-2" v-model="client.state">
                                                                <option :value="null">Selecione</option>
                                                                <option v-for="item in states" :key="item.sigla" :value="item.sigla" >{{ item.nome }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Município </label>
                                                            <select class="custom-select mr-sm-2" v-model="client.city">
                                                                <option :value="null">Selecione</option>
                                                                <option v-for="item in getCities(client.state)" :key="item" :value="item">{{ item }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-12 col-md-6 col-lg-3">
                                                        <div class="form-group">
                                                            <label>Sua residência é </label>
                                                            <select class="custom-select mr-sm-2" v-model="client.residence_type">
                                                                <option :value="null">Selecione</option>
                                                                <option value="LEASED">Alugada</option>
                                                                <option value="PARENTS">Mora com parentes/pais</option>
                                                                <option value="GIVEN">Cedida/Funcional</option>
                                                                <option value="OWN">Própria quitada</option>
                                                                <option value="FINANCED_CAIXA">Financiada pela CAIXA</option>
                                                                <option value="FINANCED">Financiada por outras entidades</option>
                                                                <option value="OTHERS">Outros</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="row mt-4" v-else>
                                                    <div class="col-12 pt-4 pb-2">
                                                        <h4 class="card-title">Endereço comercial</h4>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-3">
                                                        <div class="form-group">
                                                            <label>CEP </label>
                                                            <input type="tel" class="form-control" placeholder="74.830-500" v-model="address[0].cep" @change="getAddress">
                                                        </div>
                                                    </div>
                                                    <div class="col-12"></div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Rua/Avenida/Alameda/Travessa </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="address[0].street">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-2">
                                                        <div class="form-group">
                                                            <label>Número </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="address[0].number">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Complemento </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="address[0].complement">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Bairro </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="address[0].neighborhood">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-2">
                                                        <div class="form-group">
                                                            <label>UF </label>
                                                            <select class="custom-select mr-sm-2" v-model="address[0].state">
                                                                <option :value="null">Selecione</option>
                                                                <option v-for="item in states" :key="item.sigla" :value="item.sigla" >{{ item.nome }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Município </label>
                                                            <select class="custom-select mr-sm-2" v-model="address[0].city">
                                                                <option :value="null">Selecione</option>
                                                                <option v-for="item in getCities(address[0].state)" :key="item" :value="item">{{ item }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
    
                                                <div class="row mt-4">
                                                    <div class="col-12 pt-4 pb-2">
                                                        <h4 class="card-title">Endereço cobrança</h4>
                                                    </div>
                                                    <div class="col-12 col-md-4 col-lg-3">
                                                        <div class="form-group">
                                                            <label>CEP </label>
                                                            <input type="tel" class="form-control" placeholder="74.830-500" v-model="address[1].cep" @change="getAddress">
                                                        </div>
                                                    </div>
                                                    <div class="col-12"></div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Rua/Avenida/Alameda/Travessa </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="address[1].street">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-2">
                                                        <div class="form-group">
                                                            <label>Número </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="address[1].number">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Complemento </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="address[1].complement">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-6">
                                                        <div class="form-group">
                                                            <label>Bairro </label>
                                                            <input type="text" class="form-control" placeholder="" v-model="address[1].neighborhood">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-2">
                                                        <div class="form-group">
                                                            <label>UF </label>
                                                            <select class="custom-select mr-sm-2" v-model="address[1].state">
                                                                <option :value="null">Selecione</option>
                                                                <option v-for="item in states" :key="item.sigla" :value="item.sigla" >{{ item.nome }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Município </label>
                                                            <select class="custom-select mr-sm-2" v-model="address[1].city">
                                                                <option :value="null">Selecione</option>
                                                                <option v-for="item in getCities(address[1].state)" :key="item" :value="item">{{ item }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </b-tab>
                                    <b-tab class="tab-pane" id="imoveis">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-building font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Imóveis <b-badge variant="danger" v-show="enterprise_error">1</b-badge></span>
                                            </div>
                                        </template>
                                            <div class="card-list-legenda py-2">
                                                <div class="card-body-list-legend d-none d-lg-block">
                                                    <div class="col-12 p-0 align-self-center">
                                                        <div class="col-12">
                                                            <div class="row">
                                                                <div class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status">
                                                                    <p class="text-truncate title-legend-list"><i class="fa fa-circle color-ico-legenda font-12" data-toggle="tooltip" data-placement="top" title="Status"></i></p>
                                                                </div>
                                                                <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                                                    <p class="text-truncate title-legend-list" style="width:45px">&nbsp;</p>
                                                                </div>
                                                                <div class="col-5 col-md-8 col-lg-9 p-0 order-3 align-self-center">
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <div class="col-12 col-md-12 col-lg-3 align-self-center pad-content-list">
                                                                                <p class="text-truncate title-legend-list">Imóvel</p>
                                                                            </div>
                                                                            <div class="col-12 col-md-6 col-lg-2 align-self-center text-muted pad-content-list">
                                                                                <p class="text-truncate title-legend-list">Tipo</p>
                                                                            </div>
                                                                            <div class="col-auto col-md-auto col-lg-3 align-self-center text-muted pad-content-list">
                                                                                <p class="text-truncate title-legend-list">Unidades</p>
                                                                            </div>
                                                                            <div class="col-auto col-md-auto col-lg-2 align-self-center pad-content-list d-none d-md-block">
                                                                                <p class="text-truncate title-legend-list">ÁREA DO TERRENO (M²)</p>
                                                                            </div>
                                                                            <div class="col-12 col-md-6 col-lg-2 align-self-center pad-content-list d-none d-md-block">
                                                                                <p class="text-truncate title-legend-list">Status</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-4 col-md-2 col-lg-auto align-self-center pad-content-list order-4">
                                                                    <p class="text-truncate title-legend-list"></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-list" v-for="(item, index) in client.enterprises" :key="item.id">
                                                <div class="card-body-list">
                                                    <div class="col-12 p-0 align-self-center">
                                                        <div class="col-12">
                                                            <div class="row">
                                                                <div class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status d-none d-lg-block">
                                                                    <i class="fa fa-circle text-success font-12" data-toggle="tooltip" data-placement="top" title="Status"></i>
                                                                </div>
                                                                <div class="col-auto col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                                                    <img v-if="item.image" :src="item.image" alt="user" class="rounded-circle card-body-list-img" />
                                                                </div>
                                                                <div class="col-5 col-md-8 col-lg-9 p-0 order-3 align-self-center">
                                                                    <div class="col-12">
                                                                        <div class="row">
                                                                            <div class="col-12 col-md-12 col-lg-3 align-self-center pad-content-list">
                                                                                <h6 class="text-truncate cod"><span>CÓD.:</span> {{ item.cod }}</h6>
                                                                                <h6 class="text-truncate card-body-list-title">{{ item.name }}</h6>
                                                                            </div>
                                                                            <div class="col-12 col-md-6 col-lg-2 align-self-center text-muted pad-content-list">
                                                                                <h6 class="text-truncate empreendi">Empreendimento</h6>
                                                                            </div>
                                                                            <div class="col-auto col-md-auto col-lg-3 align-self-center text-muted pad-content-list">
                                                                                <h6 class=" empreendi" v-for="unity in item.unities" :key="unity.id">{{ unity.unity.number }} - <b v-if="unity.unity.tipology">{{ unity.unity.tipology.name }}</b></h6>
                                                                            </div>
                                                                            <div class="col-auto col-md-auto col-lg-2 mx-auto align-self-center pad-content-list d-none d-md-block">
                                                                                <h6 class="text-truncate torre">{{ item.area }}</h6>
                                                                            </div>
                                                                            <div class="col-12 col-md-6 col-lg-2 align-self-center pad-content-list">
                                                                                <h6 class="status-imovel"><small class="badge form-text text-white" style="background-color: #563d7c;">Em construção</small></h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-4 col-md-2 col-lg-auto order-3 p-lg-3 p-0 ml-auto text-right align-self-center text-muted">
                                                                    <a class="btn btn-warning text-white rounded-circle btn-circle font-16 mr-2" href="javascript:void(0);" @click="loadEnterprise(index)"><i class="far fa-edit"></i></a>
                                                                    <a class="btn btn-danger text-white rounded-circle btn-circle font-16" href="javascript:void(0);" @click="removeEnterprise(index)"><i class="far fa-trash-alt"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
    
                                            <div v-if="client.enterprises.length == 0" class="row justify-content-center">
                                                Sem imóveis
                                            </div>
    
                                            <div class="col-12 pt-4"></div>
                                            <div class="col-12 pb-2">
                                           
                                                <BaseEnterpriseSelect v-model="enterprise_id" />
                                            </div>
                                            <div class="col-12 pt-3"></div>
                                            <div class="col-12 pb-2">
                                         
                                                <BaseAptClientBox v-model="floors" :enterprise_id="enterprise_id" :edit="true" />
                                           
                                            </div>
                                            <b-button type="button" class="btn btn-info" @click="saveEnterprise" v-if="enterprise_index != null">Salvar</b-button>
    
                                            <b-button type="button" class="btn btn-info" @click="addEnterprise" v-if="enterprise_index == null">Adicionar</b-button>
                                    </b-tab>
                                    <b-tab class="tab-pane" id="configuracoes">
                                        <template #title>
                                            <div class="d-flex">
                                                <i class="far fa-sun font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Configurações <b-badge variant="danger" v-show="settings_error">1</b-badge></span>
                                            </div>
                                        </template>
                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Status do cadastro </label>
                                                            <select class="custom-select mr-sm-2" v-model="client.is_active">
                                                                <option :value="true">Ativo</option>
                                                                <option :value="false">Inativo</option>
                                                            </select>
                                                        </div>
                                                        <small id="name13" class="badge badge-default badge-danger form-text text-white">Desativar o acesso do cliente na plataforma</small>
                                                    </div>
                                                    <div class="col-12 pt-4 pb-2">
                                                        <h4 class="card-title">Senha de acesso</h4>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Resetar senha </label>
                                                            <input type="password" class="form-control" id="passtext" placeholder="********" v-model="client.password" v-validate="passwordValidate" ref="password" name="password" :class="{'is-invalid': errors.has('password')}">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4">
                                                        <div class="form-group">
                                                            <label>Confirmar senha </label>
                                                            <input type="password" class="form-control" id="passtext" placeholder="********" v-validate="confirmPasswordValidate" name="confirm_password" :class="{'is-invalid': errors.has('confirm_password')}">
                                                        </div>
                                                        <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Deve ter entre 8 e 20 caracteres.</small>
                                                    </div>
                                                    <div class="col-12" v-show="false">
                                                        <div class="form-group">
                                                            <div class="col-12 py-2">
                                                                <i class="fab fa-facebook font-20 align-middle"></i> {{ client.first_name }} {{ client.last_name }}
                                                            </div>
                                                            <button type="submit" class="btn btn-danger">Desvincular conta <i class="pl-1 far fa-trash-alt"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                 
                                    </b-tab>
                                    <div class="form-actions">
                                        <div class="text-right">
                                            <router-link :to="{name: 'Client'}" class="btn btn-dark">Cancelar</router-link>
                                            <button type="submit" class="btn btn-info ml-2">{{ id ? 'Atualizar' : 'Cadastrar' }}</button>
                                        </div>
                                    </div>
                                    </form>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    <script>
    import ClientService from "@/services/resources/ClientService";
    import ConsultDataUserService from "@/services/resources/ConsultDataUser";
    import EnterpriseService from "@/services/resources/EnterpriseService";
    import states from '@/assets/js/states.json'
    import axios from 'axios';
    import AreaCode  from "@/components/AreaCode.vue";
    
    const service = ClientService.build();
    const serviceEnterprise = EnterpriseService.build();
    const consultService = ConsultDataUserService.build();
    
    
    export default {
        components: {
            AreaCode
        },
       data(){
           return{
               client:{
                   type: [],
                   type_registration: 'natural_person',
                   client_since_date: null,
                   source: null,
                   first_name: null,
                   last_name: null,
                   cpf: null,
                   birth_date: null,
                   gender: null,
                   marital_status: null,
                   alphabetization: null,
                   nacionality: null,
                   born_city: null,
                   born_state: null,
                   father_name: null,
                   mother_name: null,
                   rg: null,
                   rg_emissor: null,
                   rg_state: null,
                   rg_date: null,
                   rg_uf: null,
                   email: null,
                   telephone: null,
                   telephone_area_code: '55',
                   telephone_area_code_iso: 'BR',
                   company_phone_area_code: '55',
                   company_phone_area_iso: 'BR',
                   cellphone_area_code: '55',
                   cellphone_area_code_iso: 'BR',
                   cellphone: null,
                   partner_first_name: null,
                   partner_last_name: null,
                   partner_cpf: null,
                   partner_birth_date: null,
                   cep: null,
                   street: null,
                   number: null,
                   complement: null,
                   neighborhood: null,
                   city: null,
                   state: null,
                   residence_type: null,
                   is_active: null,
                   password: null,
                   is_active: true,
                   enterprises: [],
                   company_date_foundation: null,
                   company_phone: null,
                   company_state: null,
                   company_city: null,
                   fantasy_name: null,
                   social_name: null,
                   cnpj: null,
                   insc_state: null,
                   insc_city: null
               },
               address: [
                    {
                        cep: null,
                        street: null,
                        number: null,
                        complement: null,
                        neighborhood: null,
                        city: null,
                        state: null,
                        address_type_id: 1
                    },
                    {
                        cep: null,
                        street: null,
                        number: null,
                        complement: null,
                        neighborhood: null,
                        city: null,
                        state: null,
                        address_type_id: 2
                    }
               ],
               address_error: false,
               settings_error: false,
               enterprise_error: false,
               legal_entity_error: false,
               basic_error: false,
               error_information: false,
               id: null,
               states: [],
               test: [],
               pages:[
                    {
                        name: 'dashboard',
                        to: 'Dashboard'
                    },
                    {
                        name: 'novo cliente',
                        to: 'ClientNew'
                    }
                ],
                menu:[
                    {
                        name: 'Novo cliente',
                        to: 'ClientNew'
                    },
                    {
                        name: 'Clientes',
                        to: 'Client'
                    }
                ],
                enterprise_id: null,
                floors: [],
                enterprises_list: [],
                floor: null,
                enterprise_index: null
           }
       },
       computed:{
           passwordValidate(){
               return this.id ? '' : 'required|min:8';
           },
           confirmPasswordValidate(){
               return this.id ? '' : 'required|confirmed:password';
           }
       },
       methods:{
            setPhoneAreaCode(value) {
           
                this.$set(this.client, 'telephone_area_code', value);
            },
            setCellPhoneAreaCode(value) {
                this.$set(this.client, 'cellphone_area_code', value);
            },
            setCompanyAreaCode(value) {
                this.$set(this.client, 'company_phone_area_code', value);
            },
           getAddress(){
              
              if(this.client.cep.length === 10) {
                let url = `https://viacep.com.br/ws/${this.client.cep.replace('-', '').replace('.', '')}/json`;
    
                    axios.get(url).then(resp =>{
                        this.client.street = resp.data.logradouro;
                        this.client.neighborhood = resp.data.bairro;
                        this.client.state = resp.data.uf;
                        this.client.city = resp.data.localidade;
                    });
              }
    
       
           },
           loadEnterprise(index){
               this.enterprise_index = index;
               this.enterprise_id = this.client.enterprises[index].id;
               this.floors = this.client.enterprises[index].unities.map(elem => elem.unity_id)
           },
           verifyDataUser(type){
    
                let data     = {};
                data['role_id'] = 2;//CLIENT
                
                if(type === 'cpf') {
      
                    data['cpf'] = this.client.cpf;
          
                    consultService
                        .search(data)
                        .catch(err => {
                            this.$bvToast.toast('Cpf já utilizado para este tipo de usuário', {
                                title: 'Criação do cliente',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        })
    
                } else {
    
                        data['email'] = this.client.email;
                
                         consultService
                        .search(data)
                        .catch(err => {
                            this.$bvToast.toast('E-mail já utilizado para este tipo de usuário', {
                                title: 'Criação do cliente',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        })
    
                }
    
           },
           saveEnterprise(){
               this.client.enterprises[this.enterprise_index].floors = this.floors;
               this.floors = [];
               this.enterprise_id = null;
               this.enterprise_index = null;
           },
           getype(value){       
    
            this.$delete(this.client, 'type_registration');
            this.$set(this.client, 'type_registration', value);

            if(value === 'natural_person') {
                this.$set(this.client, 'cnpj', '');
            } else {
                this.$set(this.client, 'cpf', '');
            }
        
        },
           getCities(uf){
               
               if(!uf)
                    return []
    
               let state = this.states.filter(elem => {
                   return elem.sigla === uf;
               });
               if(!state.length)
                    return state;
                    
               return state[0].cidades;
    
           },
           validateBasicTab(){
                if(!this.client.type.length){
                    this.$bvToast.toast('Selecione a permissão do cliente.', {
                        title: 'Criação do cliente',
                        autoHideDelay: 5000,
                        type: 'danger'
                    }) 
                }
    
                if(this.client.type_registration !== 'natural_person') {
                    return true;
                }
    
                if((!this.client.email || !this.client.cellphone || !this.client.first_name)){
                 
                    this.basic_error = true;
                    return false;
              
                } 
    
               this.basic_error = false;
    
               return true;
           },
           validateEnterpriseTab(){
               if(this.client.enterprises.length == 0){
                   this.enterprise_error = true;
                   return false;
               }
    
               return true;
           },
           removeEnterprise(index){
               this.$delete(this.client.enterprises, index);
               this.$forceUpdate();
           },
           async addEnterprise(){
               if(!this.enterprise_id || this.floors.length == 0){
                   this.$bvToast.toast('Selecione um imóvel e pelo menos um apartamento.', {
                        title: 'Adicionar imóvel',
                        autoHideDelay: 5000,
                        type: 'danger'
                    })
                   return;
               }
                let enterprise = await serviceEnterprise.read({id: this.enterprise_id}).then();
                enterprise.floors = this.floors;
                this.floors = [];
                this.enterprise_id = null;
                this.client.enterprises.push(enterprise);
           },
           validateSettingTab(){
               if(!this.client.password && !this.id){  
                    this.settings_error = true;
                    return false;
                }
    
                return true;
           },
           validateInformationTab() {
    
            if(!this.client.type.length ){  
                    this.error_information = true;
    
                    return false;
            }
    
            this.error_information = false;
    
            return true;
    
           },
           legalEntityError() {
            
    
            if(this.client.type_registration === 'natural_person') {
                return true
            }
            //!this.client.fantasy_name ||
            if(!this.client.email ||  !this.client.social_name) {
                this.legal_entity_error = true;
                return false;
            }
    
            this.legal_entity_error = false;
            return true;
    
    
           },
           getEnterprises(enterprises){
               let data = [];
    
                for(let i = 0; i < this.enterprises.length; i++){
                    for(let j = 0; j < enterprises.length; j++){
                        if(this.enterprises[i].id == enterprises[j].enterprise_id){
                            data.push(this.enterprises[i]);
                            data.floors = enterprises[j].floors.map(elem => {
                                return elem.apto
                            });   
                        }
                    }
                }
    
                return data;
    
           },
           save(){
            this.basic_error = false;
            this.settings_error = false;
            this.enterprise_error = false;
    
            this.$validator.validateAll().then((result) => {
              
                if (this.validateInformationTab() & this.legalEntityError() & this.validateBasicTab() & this.validateSettingTab() & this.validateEnterpriseTab()) {
    
                    let client = Object.assign({}, this.client)
                    client.type = this.client.type.join(',');
    
                    if(this.id){
                        client.id = this.id;
                        for(let i = 0; i < client.enterprises.length; i++){
                            if(typeof client.enterprises[i].floors == 'undefined'){
                                client.enterprises[i].floors = client.enterprises[i].unities.map(elem => elem.unity_id);
                            }
                        }
    
                        service
                        .update({...client, address: this.address})
                        .then(resp => {
                            this.$bvToast.toast('Seu cliente foi atualizado com sucesso!', {
                                title: 'Cliente atualizado',
                                autoHideDelay: 5000,
                                type: 'success'
                            })
                            this.fetchClient();
                        })
                        .catch(err => {
                            this.$bvToast.toast('Este e-mail já foi cadastrado no sistema.', {
                                title: 'Criação do cliente',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        })
                    }else{
                        service
                        .create({...client, address: this.address})
                        .then(resp => {
                            this.$bvToast.toast('Seu cliente foi criado com sucesso!', {
                                title: 'Cliente criado',
                                autoHideDelay: 5000,
                                type: 'success'
                            })
                            this.fetchClient();
                        })
                        .catch(err => {
                            this.$bvToast.toast('Este e-mail já foi cadastrado no sistema.', {
                                title: 'Criação do cliente',
                                autoHideDelay: 5000,
                                type: 'danger'
                            })
                        })
                    }
    
              
                }
            });
           },
           fetchClient(){
    
                if(!this.id){
                    return;
                }
    
                let data = {
                    id: this.id
                }
    
                service
                .read(data)
                .then(resp => {
                    this.client = resp.client;
                    this.client.first_name = resp.first_name;
                    this.client.last_name = resp.last_name;
                    this.client.cpf = resp.cpf;
                    this.client.cellphone = resp.cellphone;
                    this.client.email = resp.email;
                    this.client.type = resp.client.type.split(',');
                    this.client.password = null;
                    this.enterprises_list = resp.enterprises;
                    this.client.is_active = this.client.is_active == 1;
                    this.client.social_name = resp.social_name;
                    this.client.fantasy_name = resp.fantasy_name;
                    this.client.cnpj = resp.cnpj;
                    this.client.company_phone = resp.company_phone;
                    this.client.company_date_foundation = resp.company_date_foundation;
                    this.client.company_city = resp.company_city;
                    this.client.company_state = resp.company_state;
                    this.client.insc_city = resp.insc_city;
                    this.client.insc_state = resp.insc_state;

                    this.client.telephone_area_code = resp.telephone_area_code ? resp.telephone_area_code : '55';
                    this.client.telephone_area_code_iso = typeof resp.client.telephone_area_code_iso !== 'undefined'? resp.client.telephone_area_code_iso.iso : '55';

                    this.client.company_phone_area_code = resp.company_phone_area_code ? resp.company_phone_area_code : '55';
                    this.client.company_phone_area_code_iso = resp.company_phone_area_code_iso ? resp.company_phone_area_code_iso.iso : '55';
                    
                    this.client.cellphone_area_code = resp.cellphone_area_code ? resp.cellphone_area_code : '55';
                    this.client.cellphone_area_code_iso = resp.cellphone_area_code_iso ? resp.cellphone_area_code_iso.iso : '55';

                    this.client.enterprises = resp.enterprises.map(elem => {
                        let data = elem.enterprise;
                        data.unities = elem.unities;
                        return data;
                    });
                    
                    if(this.client.cpf) {
                        this.client.type_registration =  'natural_person';
                    } else {
                        this.client.type_registration = 'legal_entity';
                    }
    
                    resp.client.addresses.forEach(address => {
                        if(address.type.id === 1) {
                            this.address[0] = address;
                        } else {
                            this.address[1] = address;
                        }
                    });
                   
                })
                .catch(err => {
                 
                console.log(err)
                })
    
           },
       },
       mounted(){
           this.states = states;
           this.id = this.$route.params.id;
           this.fetchClient();
       }
    }
    </script>